



















































































































































































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import moment from 'moment'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',
      'filter', 'cities', 'promotions',
      'report_data',
    ]),
    ...mapGetters(STORE_KEY, [
      'summary',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class ReportByChannel extends Vue {

  public message: string = ''
  public send_datetime: any = moment().toDate()
  public sending: boolean = false
  public showModal: any = false
  public showModalNotifications: any = false
  public filter!: any
  public promotion: any = null
  public dropFiles: any = null
  public sexs: any[] = [
    { text: 'мужской', value: 'm' },
    { text: 'женский', value: 'f' },
    { text: 'не важно', value: null },
  ]
  public types: any[] = [
    { text: 'все', value: null },
    { text: 'списание', value: 'write-off' },
    { text: 'начисление', value: 'accural' },
  ]
  public checkedCustomers: any[] = []

  get titleStack() {
    return [
      'Отчет по покупателям',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  public setFilter() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter)
    this.showModal = false
  }

  public sendNotification() {
    if (!this.message || !this.send_datetime) {
      // nothing
    }
    else {
      this.sending = true
      this.$store.dispatch(`${STORE_KEY}/sendNotification`, {
        message: this.message,
        send_datetime: this.send_datetime,
        file: this.dropFiles,
        promotion: this.promotion,
        customers: this.checkedCustomers,
      }).then(() => {
        this.sending = false
        this.message = ''
        this.send_datetime = moment().toDate()
        this.dropFiles = null
        this.promotion = null
        this.showModalNotifications = false
        this.$buefy.snackbar.open(`Рассылка выполнена!`)
      }).catch(() => {
        this.sending = false
      })
    }
  }

  public uploadFile(files: any) {
    const formData = new FormData()

    formData.append('file', files)

    // @ts-ignore
    this.$http
      .post(`/admins/rest/v1/uploads`, formData, {})
      .then((response: any) => {
        this.dropFiles = response.data.upload
      })

    // // @ts-ignore
    // this.$v.form.promotion.image_upload_id.$touch();
  }

  // ---------------------------------------------------------------------------

  public created() {
    // console.log(this.$store._modules.root._children)
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/preloadData`)
    this.$store.dispatch(`${STORE_KEY}/loadData`, {})
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
