import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'

interface ReportByChannelState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  report_data: any[];
  cities: any[];
  promotions: any[];
  filter: any;
}

const $state: ReportByChannelState = {
  loading: false,
  loaded: false,
  fatalError: false,

  report_data: [],
  cities: [],
  promotions: [],
  filter: {
    age_from: 18,
    age_to: 99,
    cities: [],
    common_mark_from: 0,
    common_mark_to: 0,
    date_enter_from: null,
    date_enter_to: null,
    date_from: null,
    date_to: null,
    feedbacks_count_from: 0,
    feedbacks_count_to: 0,
    marks_count_from: 0,
    marks_count_to: 0,
    receipts_count_from: 0,
    receipts_count_to: 0,
    receipts_sum_from: 0,
    receipts_sum_to: 0,
    sex: null,
    type: null,
    visit_count_from: 0,
    visit_count_to: 0,
  },
}

export const $actions: ActionTree<ReportByChannelState, RootState> = {
  loadData({ commit, getters, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      params.age_from = query.age_from ? query.age_from : null
      params.age_to = query.age_to ? query.age_to : null
      params.date_enter_from = query.date_enter_from ? query.date_enter_from : null
      params.date_enter_to = query.date_enter_to ? query.date_enter_to : null
      params.sex = query.sex ? query.sex : null
      params.age_from = query.age_from ? query.age_from : null
      params.age_to = query.age_to ? query.age_to : null
      params.cities = query.cities ? JSON.stringify(query.cities.map((item: any) => {
        return item.id
      })) : null
      params.common_mark_from = query.common_mark_from ? query.common_mark_from : null
      params.common_mark_to = query.common_mark_to ? query.common_mark_to : null
      params.date_enter_from = query.date_enter_from ? query.date_enter_from : null
      params.date_enter_to = query.date_enter_to ? query.date_enter_to : null
      params.date_from = query.date_from ? query.date_from : null
      params.date_to = query.date_to ? query.date_to : null
      params.feedbacks_count_from = query.feedbacks_count_from ? query.feedbacks_count_from : null
      params.feedbacks_count_to = query.feedbacks_count_to ? query.feedbacks_count_to : null
      params.marks_count_from = query.marks_count_from ? query.marks_count_from : null
      params.marks_count_to = query.marks_count_to ? query.marks_count_to : null
      params.receipts_count_from = query.receipts_count_from ? query.receipts_count_from : null
      params.receipts_count_to = query.receipts_count_to ? query.receipts_count_to : null
      params.receipts_sum_from = query.receipts_sum_from ? query.receipts_sum_from : null
      params.receipts_sum_to = query.receipts_sum_to ? query.receipts_sum_to : null
      params.sex = query.sex ? query.sex : null
      params.type = query.type ? query.type : null
      params.visit_count_from = query.visit_count_from ? query.visit_count_from : null
      params.visit_count_to = query.visit_count_to ? query.visit_count_to : null

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/reports/by_customer', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve(null)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  preloadData({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
      vm.$http
        .get(`/admins/rest/v1/promotion`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_PROMOTIONS', response.data)
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
  sendNotification({ state }, params) {
    console.log(params)
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      const notification: any = {
        message: params.message,
        send_datetime: params.send_datetime,
      }
      notification.file_id = params.file ? params.file.id : null
      notification.promotion_id = params.promotion ? params.promotion.id : null
      notification.customers = params.customers ? params.customers.map((item: any) => {
        return item.id
      }) : null
      vm.$http
        .post(`/admins/rest/v1/notifications`, { notification })
        .then((response: AxiosResponse) => {
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          reject(error.response?.data)
        })
    })
  },
}

export const $mutations: MutationTree<ReportByChannelState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_ITEMS_LIST(state, data) {
    const _report_data: any = {}
    data.data.forEach((item: any) => {
      if (!_report_data[item.customer_id]) {
        _report_data[item.customer_id] = {
          id: item.customer_id,
          customer_id: item.customer_id,
          customer_name: item.customer_name,
          brand_id: item.brand_id,
          brand_name: item.brand_name,
          vizited_outlets: 0,
          receipts_count: 0,
          receipts_sum: 0,
          feedbacks_count: 0,
          marks_count: 0,
          common_mark: 0,
          bonus_writed: 0,
          bonus_accural: 0,
          balance: item.balance,
          items: {},
        }
      }
      if (!_report_data[item.customer_id].items[item.brand_id]) {
        _report_data[item.customer_id].items[item.brand_id] = {
          customer_id: item.customer_id,
          customer_name: item.customer_name,
          brand_id: item.brand_id,
          brand_name: item.brand_name,
          vizited_outlets: item.vizited_outlets,
          receipts_count: item.receipts_count,
          receipts_sum: item.receipts_sum,
          feedbacks_count: item.feedbacks_count,
          marks_count: item.marks_count,
          common_mark: item.common_mark,
          bonus_writed: item.bonus_writed,
          bonus_accural: item.bonus_accural,
        }
      }
      _report_data[item.customer_id].vizited_outlets += item.vizited_outlets
      _report_data[item.customer_id].receipts_count += item.receipts_count
      _report_data[item.customer_id].receipts_sum += item.receipts_sum
      _report_data[item.customer_id].feedbacks_count += item.feedbacks_count
      _report_data[item.customer_id].marks_count += item.marks_count
      _report_data[item.customer_id].common_mark += item.common_mark
      _report_data[item.customer_id].bonus_writed += item.bonus_writed
      _report_data[item.customer_id].bonus_accural += item.bonus_accural
      _report_data[item.customer_id].balance = item.balance > 0 ? item.balance : _report_data[item.customer_id].balance
    })
    state.report_data = Object.values(_report_data).map((item: any) => {
      item.items = Object.values(item.items)
      item.common_mark = item.common_mark / item.items.length
      return item
    })
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  SET_PROMOTIONS: (state, { promotions }) => {
    state.promotions = promotions
  },
}

export const $getters:
  GetterTree<ReportByChannelState, RootState> = {
  summary(state) {
    const sum = {
      vizited_outlets: 0,
      receipts_count: 0,
      receipts_sum: 0,
      feedbacks_count: 0,
      marks_count: 0,
      common_mark: 0,
      bonus_writed: 0,
      bonus_accural: 0,
      balance: 0,
    }
    let common_mark = 0
    let cnt_common_mark = 0
    state.report_data.forEach((obj) => {
      sum.vizited_outlets += obj.vizited_outlets
      sum.receipts_count += obj.receipts_count
      sum.receipts_sum += obj.receipts_sum
      sum.feedbacks_count += obj.feedbacks_count
      sum.marks_count += obj.marks_count
      sum.bonus_writed += obj.bonus_writed
      sum.bonus_accural += obj.bonus_accural
      sum.balance += obj.balance
      common_mark += obj.common_mark
      cnt_common_mark += obj.items.length
    })
    sum.common_mark = common_mark / cnt_common_mark
    return sum
  },
}

export const STORE_KEY = '$_report_by_customer'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
